import React, { createContext, Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { usePersistentState } from '../shared/persistant-state'
import { i18n } from '../i18n/instance'
import { MpriseAuthProvider, MpriseUser } from '@mprise/react-auth'
import { useLazyQuery } from '@apollo/client'
import { RESOURCE_BY_EXTERNAL_ID } from '../gql/resources'

export enum ScanningSetting {
  CAMERA = 'CAMERA',
  SCANNER = 'SCANNER',
  KEYBOARD = 'KEYBOARD',
}
export enum NumberInputMethod {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}
export const AppSettingsContainer = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = usePersistentState<AppLanguage>('__LANGUAGE_GH_MS', {})
  const [company, setCompany] = usePersistentState<CompanySubset>('__DEFAULT_COMPANY_GH_MS', {})
  const [resource, setResource] = usePersistentState<ResourceSubset | null>('__RESOURCE_GH_MS', null)
  const [scanSetting, setScanSetting] = usePersistentState<ScanningSetting>('SCANNING_GH_MS', ScanningSetting.CAMERA)
  const [defaultNumberInputMethod, setDefaultNumberInputMethod] = usePersistentState<NumberInputMethod>(
    '__DEFAULT_NUMBER_INPUT_METHOD_GH_MS',
    NumberInputMethod.AMOUNT,
  )
  const [numberFormat, setNumberFormat] = usePersistentState<NumberFormatItem>(
    '__NUMBER_FORMAT_GH_MS',
    availableNumberFormats[0]!,
  )
  const [showPositionWhileSorting, setShowPositionWhileSorting] = usePersistentState<boolean>(
    '__DEFAULT_POSITION_VISIBILITY_GH_MS',
    true,
  )

  useEffect(() => {
    const lang = language.id ?? 'en'
    i18n.changeLanguage(lang)
  }, [language])

  const userFromAuthContext = MpriseAuthProvider.useContext().user as MpriseUser & { sub: string }

  const [getResourceByExternalId, { data: resourceData }] = useLazyQuery(RESOURCE_BY_EXTERNAL_ID, {
    onError: console.error,
  })

  useEffect(() => {
    const subjectId = userFromAuthContext?.sub

    if (subjectId) {
      getResourceByExternalId({
        variables: {
          filter: {
            externalUserId: subjectId,
          },
        },
      })
    }
  }, [getResourceByExternalId, userFromAuthContext])

  useEffect(() => {
    const resourceFromDatabase = resourceData?.resource
    if (!resource && resourceFromDatabase) {
      setResource({
        id: resourceFromDatabase?.id ?? '',
        name: resourceFromDatabase?.name ?? '',
        code: resourceFromDatabase?.code ?? '',
        cosmosKey: resourceFromDatabase?.cosmosKey ?? '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: only supposed to trigger when the resource query returns.
  }, [resourceData])

  return (
    <AppSettingsContext.Provider
      value={{
        language,
        setLanguage,
        company,
        setCompany,
        resource,
        setResource,
        scanSetting,
        setScanSetting,
        numberFormat,
        setNumberFormat,
        defaultNumberInputMethod,
        setDefaultNumberInputMethod,
        showPositionWhileSorting,
        setShowPositionWhileSorting,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  )
}

export const useAppSettingsContext = () => {
  const context = useContext(AppSettingsContext)
  if (!context) {
    throw new Error('Context called without a Provider.')
  }
  return context
}

const AppSettingsContext = createContext<
  | {
      language: AppLanguage
      setLanguage: Dispatch<SetStateAction<AppLanguage>>
      company: CompanySubset
      setCompany: Dispatch<SetStateAction<CompanySubset>>
      resource: ResourceSubset | null
      setResource: Dispatch<SetStateAction<ResourceSubset | null>>
      scanSetting: ScanningSetting
      setScanSetting: Dispatch<SetStateAction<ScanningSetting>>
      numberFormat: NumberFormatItem
      setNumberFormat: Dispatch<SetStateAction<NumberFormatItem>>
      defaultNumberInputMethod: NumberInputMethod
      setDefaultNumberInputMethod: Dispatch<SetStateAction<NumberInputMethod>>
      showPositionWhileSorting: boolean
      setShowPositionWhileSorting: Dispatch<SetStateAction<boolean>>
    }
  | undefined
>(undefined)

export const availableLanguages = ['en', 'es', 'nl']
export const availableNumberFormats: NumberFormatItem[] = [
  { id: 'DECIMAL_COMMA', name: 'Decimal Comma', decimalSeparator: ',', groupSeparator: '.' },
  { id: 'DECIMAL_DOT', name: 'Decimal Dot', decimalSeparator: '.', groupSeparator: ',' },
]

interface AppLanguage {
  id?: string
  name?: string
}
interface CompanySubset {
  id?: string
  name?: string
}
interface ResourceSubset {
  id?: string
  code?: string
  name?: string
  cosmosKey?: string
}
export interface NumberFormatItem {
  id: string
  name: string
  decimalSeparator: string
  groupSeparator: string
}
